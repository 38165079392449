export default {
  rank: {
    novice: 't_NOVICENOMAD',
    avid: 't_AVIDTRAVELLER',
    globe: 't_GLOBETROTTER',
  },
  sort: {
    newest: '-date',
    oldest: 'date',
    topRated: '-rating',
    lowestRated: 'rating',
    ageGroup: 'age-group',
  },
  reviewsLanguage: [
    {
      value: 'specific',
      disabled: false,
      label: 't_LANGUAGEREVIEWS',
    },
    {
      value: 'all',
      disabled: false,
      label: 't_ALLREVIEWSTRANSINTOLANG',
    },
  ],
  defaultReviewLanguage: 'specific',
  reviewsMonthCount: 36,
  defaultSortOld: 'Newest',
  defaultSort: '-date',
  rankLevel: {
    novice: 10,
    avid: 20,
  },
  groupTypes: [
    {
      value: 'MALE',
      label: 't_MALE',
      code: 'male',
    },
    {
      value: 'FEMALE',
      label: 't_FEMALE',
      code: 'female',
    },
    {
      value: 'COUPLE',
      label: 't_COUPLE',
      code: 'couple',
    },
    {
      value: 'ALLMALEGROUP',
      label: 't_ALLMALEGROUP',
      code: 'allMaleGroup',
    },
    {
      value: 'ALLFEMALEGROUP',
      label: 't_ALLFEMALEGROUP',
      code: 'allFemaleGroup',
    },
    {
      value: 'MIXEDGROUP',
      label: 't_MIXEDGROUP',
      code: 'mixedGroup',
    },
  ],
  ageGroups: [
    {
      value: '18-24',
      label: '18-24',
      code: '18-24',
    },
    {
      value: '25-30',
      label: '25-30',
      code: '25-30',
    },
    {
      value: '31-40',
      label: '31-40',
      code: '31-40',
    },
    {
      value: '41+',
      label: '41+',
      code: '41+',
    },
  ],
  tripTypes: [
    {
      value: 'COLLEGEBREAK',
      label: 't_COLLEGEBREAK',
      code: 'collegeBreak',
    },
    {
      value: 'WEEKENDAWAY',
      label: 't_WEEKENDAWAY',
      code: 'weekendAway',
    },
    {
      value: 'GAPYEAR',
      label: 't_GAPYEAR',
      code: 'gapYear',
    },
    {
      value: 'RTWTRIP',
      label: 't_RTWTRIP',
      code: 'rtwTrip',
    },
    {
      value: 'REGULARVACATION',
      label: 't_REGULARVACATION',
      code: 'regularVacation',
    },
    {
      value: 'OTHER',
      label: 't_OTHER',
      code: 'other',
    },
  ],
};
