import reviewsConfig from '@/config/reviews';
import { useCurrencyStore } from '@/stores/currency';

export function useApiProperties() {
  const {
    $api,
    $i18n,
  } = useNuxtApp();
  const { locale } = $i18n;

  const currencyStore = useCurrencyStore();

  const dateTime = useDateTime();

  const userCurrency = currencyStore.getCurrentCurrency;

  const defaultQuery = { currency: userCurrency?.code };

  const defaultHeaders = { 'Accept-Language': locale?.value };

  const defaultParams = {};

  const formulateAuthorRank = (numberOfReviews) => {
    if (numberOfReviews <= reviewsConfig.rankLevel.novice) {
      return reviewsConfig.rank.novice;
    }

    if (numberOfReviews <= reviewsConfig.rankLevel.avid) {
      return reviewsConfig.rank.avid;
    }

    return reviewsConfig.rank.globe;
  };

  const calculatePropertyOverallRating = (ratingTotal) => {
    if (ratingTotal <= 0 || ratingTotal > 100) {
      return 0;
    }
    return Number((ratingTotal / 10).toFixed(1));
  };

  const formatPropertyReviewResponse = (reviewResponse) => {
    return {
      pagination: reviewResponse.pagination,
      reviews: reviewResponse.reviews.map((review) => ({
        overallRating: calculatePropertyOverallRating(review?.rating?.overall),
        reviewCard: {
          ownerComment: review?.ownerComment,
          reviewDate: dateTime.formatToHumanReadableShortDate(review?.date, review?.languageCode, 'month|year'),
          reviewText: review?.notes,
        },
        author: {
          gender: `${review?.groupInformation?.groupTypeCode[0].toUpperCase()}${review?.groupInformation?.groupTypeCode.slice(1).toLowerCase()}`,
          nickname: review?.user?.nickname,
          numberOfReviews: review?.user?.numberOfReviews,
          rank: formulateAuthorRank(review?.user?.numberOfReviews),
          countryCode: review?.user?.nationality?.code,
          countryName: review?.user?.nationality?.name,
          ageRange: review?.groupInformation?.age,
        },
      })),
    };
  };

  const getPropertyByPropertyId = async (propertyId, extraInfo = true) => {
    let propertyInfo = ref(null);
    let extraPropertyInfo = {};

    const callKeySpapi = `getPropertyByPropertyId${locale?.value}${propertyId}${userCurrency?.code}`;
    const endpointSpapi = `property/${propertyId}/`;
    const resultSpapi = ref(await $api.spapi(callKeySpapi, endpointSpapi, defaultHeaders, defaultQuery, defaultParams));
    propertyInfo = resultSpapi?.value?.data || {};

    if (propertyInfo?.id && extraInfo) {
      const callKeyMsApi = `getPropertyByPropertyIdMsApi${locale?.value}${propertyId}${userCurrency?.code}`;
      const endpointMsApi = `property/${propertyId}/`;
      const resultMsApi = ref(
        await $api.msapi(callKeyMsApi, endpointMsApi, defaultHeaders, defaultQuery, defaultParams),
      );
      const propertyMsApiInfo = toValue(resultMsApi) || {};

      const callKeyHwApi = `getPropertyByPropertyIdMHwApi${locale?.value}${propertyId}${userCurrency?.code}`;
      const endpointHwApi = `properties/${propertyId}/`;
      const resultHwApi = ref(
        await $api.hwapi(callKeyHwApi, endpointHwApi, defaultHeaders, defaultQuery, defaultParams),
      );
      const propertyHwApiInfo = toValue(resultHwApi) || {};

      const checkInOutPolicy = {
        checkIn: {
          startsAt: `${propertyMsApiInfo?.houseRules?.checkin?.from}:00`,
          endsAt: `${propertyMsApiInfo?.houseRules?.checkin?.until}:00`,
        },
        checkOut: { endsAt: propertyMsApiInfo?.houseRules?.latestCheckout },
      };
      propertyInfo.policies = {
        ...propertyInfo.policies,
        ...checkInOutPolicy,
        thingsToNote: propertyMsApiInfo?.houseRules?.notes || null,
      };

      extraPropertyInfo = {
        isNew: propertyMsApiInfo.isNew,
        videos: propertyMsApiInfo?.videos,
        recommendedByHw: propertyMsApiInfo?.hostelworldRecommends,
        hoscars: propertyMsApiInfo?.hoscars,
        hostelworldSays: propertyMsApiInfo?.hostelworldSays,
        facilities: propertyMsApiInfo?.facilities,
        depositPercentage: propertyMsApiInfo.depositPercentage,
        currency: propertyMsApiInfo.currency,
        lowestPrice: propertyHwApiInfo?.lowestPricePerNight?.value || 0,
        // ... add more fields here from HWAPI or MSAPI as needed
      };
    }

    return {
      ...propertyInfo,
      ...extraPropertyInfo,
    };
  };

  const getPropertyReviews = async (propertyId, filters) => {
    let result = ref(null);

    const callKey = `getPropertyReviews${locale?.value}${propertyId}${userCurrency?.code}`;
    const endpoint = `properties/${propertyId}/reviews/`;

    const callParams = {
      ...defaultParams,
      ...filters,
    };

    result = ref(await $api.hwapi(callKey, endpoint, defaultHeaders, defaultQuery, callParams));

    return formatPropertyReviewResponse(result?.value) || null;
  };

  return {
    getPropertyByPropertyId,
    getPropertyReviews,
  };
}
